import React from 'react'
import {useSelector, useDispatch} from "react-redux";
import {
    SocialNetworksViewWrapper,
    SocialNetworksViewHeader,
    SocialNetworksViewTitle,
    SocialNetworksViewBody,
    SocialNetworksViewItem,
    SocialNetworksViewBtn
} from "./style";
import {FormattedMessage} from "react-intl";
import Checkbox from "../../../../components/Form/Checkbox";
import {merchantThemes} from "../../../../components/Variable";
import {FormTermsHolder, LinkUnderline} from "../MainForm/style";
import {showPopup} from "../../../../components/Modal/slices";
import {toggleSocialView} from "../../slices";
import {Icon} from "../../../../components/Icon";

const SocialNetworksView = ({ currentTheme = {} }) => {
    const dispatch = useDispatch();
    const {terms, policy} = useSelector((state) => state.main);
    const openPolicyPopupWindow = (e) => {
        e.preventDefault();
        console.log('policy')
        dispatch(showPopup({
            type: 'policy',
            data: policy
        }))
    }
    const openTermsPopupWindow = (e) => {
        e.preventDefault();
        console.log('terms')
        dispatch(showPopup({
            type: 'terms',
            data: terms
        }))
    }
    return (
        <SocialNetworksViewWrapper>
            <SocialNetworksViewHeader>
                <SocialNetworksViewTitle>
                    <FormattedMessage
                        id='login.intro.header'
                        defaultMessage='Login with email'
                    />
                </SocialNetworksViewTitle>
            </SocialNetworksViewHeader>
            <SocialNetworksViewBody>
                <SocialNetworksViewItem>
                    <SocialNetworksViewBtn className='returndao-google'onClick={()=>{
                        dispatch(showPopup({
                            type: 'inDevelopment'
                        }))
                    }}>
                        <Icon component='googleIcon'/>
                        Sign in with Google
                    </SocialNetworksViewBtn>
                </SocialNetworksViewItem>
                <SocialNetworksViewItem>
                    <SocialNetworksViewBtn className='returnado-facebook'onClick={()=>{
                        dispatch(showPopup({
                            type: 'inDevelopment'
                        }))
                    }}>
                        <Icon component='facebookIcon' />
                        Sign in with Facebook
                    </SocialNetworksViewBtn>
                </SocialNetworksViewItem>
                <SocialNetworksViewItem>
                    <FormattedMessage
                        id='reclamation.or'
                        defaultMessage="or"
                    />
                </SocialNetworksViewItem>
                <SocialNetworksViewItem>
                    <SocialNetworksViewBtn className='returnado-email' onClick={()=>{
                        dispatch(toggleSocialView())
                    }}>
                        <Icon component='email'/>
                        Continue with email
                    </SocialNetworksViewBtn>
                </SocialNetworksViewItem>

                <FormTermsHolder>
                    <Checkbox
                        //register={register}
                        name='termAndCond'
                        //error={false}
                    >
                        <FormattedMessage
                            id='login.form.termsAndConditions'
                            defaultMessage="I accept Returnado's"
                        />{' '}
                        {!!(terms && currentTheme && currentTheme.name !== merchantThemes.marysia) && (
                            <LinkUnderline onClick={openTermsPopupWindow}>
                                <FormattedMessage
                                    id='login.form.termsAndConditions.link'
                                    defaultMessage='Terms and Conditions'
                                />
                            </LinkUnderline>)}{' '}
                        {(!!(currentTheme && currentTheme.name !== merchantThemes.marysia) && <FormattedMessage
                            id='login.form.termsAndConditions.label'
                            defaultMessage='and'
                        />)}{' '}
                        {!!policy && (<LinkUnderline onClick={openPolicyPopupWindow}>
                            <FormattedMessage
                                id='login.form.privacyPolicy.link'
                                defaultMessage='Privacy Policy'
                            />
                        </LinkUnderline>)}
                    </Checkbox>
                </FormTermsHolder>
            </SocialNetworksViewBody>
        </SocialNetworksViewWrapper>
    )
}
export default SocialNetworksView
