import { createSlice } from '@reduxjs/toolkit'
import {getFilteredShopCodes} from "../../utils/common";


export const mainSlice = createSlice({
	name: 'main',
	initialState: {
		isLoading: false,
		terms: [],
		isEmailPopup: false,
		policy: [],
		codes: null,
		isLoggedIn: false,
		isSocialView: null // need to set true to show social media login form
	},
	reducers: {
		getEmailStart: (state) => {
			state.isLoading = true
		},
		getEmailError: (state) => {
			state.isLoading = false
		},
		getLoginStart: (state) => {
			state.isLoading = true
		},
		getLoginError: (state) => {
			state.isLoading = false
		},
		closeEmailPopup: (state) => {
			state.isEmailPopup = false
		},
		/**
		 * getTerms {Function}
		 * @description - set an appropriate terms data to the state.
		 * */
		getTerms: (state, { payload }) => {
			state.terms = payload
		},
		/**
		 * getPolicy {Function}
		 * @description - set an appropriate policy data to the state.
		 * */
		getPolicy: (state, { payload }) => {
			state.policy = payload
		},
		/**
		 * getCodes {Function}
		 * @description - set an appropriate codes data to the state.
		 * */
		getCodes: (state, { payload }) => {
			let {codes, theme} = payload;
			state.codes = getFilteredShopCodes(codes, theme);
		},
		setLoggedIn: (state, { payload }) => {
			state.isLoggedIn = true
		},
		setLoggedOut: (state, { payload })=>{
			state.isLoggedIn = false
		},
		toggleSocialView: (state, { payload })=>{
			state.isSocialView = !state.isSocialView
		}
	},
})

export const {
	getLoginError,
	getTerms,
	getPolicy,
	getCodes,
	getEmailStart,
	getEmailSuccess,
	getEmailError,
	setLoggedIn,
	setLoggedOut,
	toggleSocialView
} = mainSlice.actions
export const { reducer } = mainSlice
