import styled, {css} from 'styled-components'
import {rem} from '../../utils/common'

export const LoaderWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: none;
	background-color: rgba( 0, 0, 0, 0.7);
	z-index: 21;
	align-items: center;
	
	${(p) => p.isActive && css`
       display: flex;
    `}
`
export const LoaderItem = styled.div`
    margin: ${rem(60)} auto;
    font-size: ${rem(10)};
    position: relative;
    text-indent: ${rem(-999999)};
    border-top: ${rem(4.1)} solid rgba(0,0,0, 0.2);
    border-right: ${rem(4.1)} solid rgba(0,0,0, 0.2);
    border-bottom: ${rem(4.1)} solid rgba(0,0,0, 0.2);
    border-left: ${rem(4.1)} solid #fff;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    
    &,
    &:after {
      border-radius: 50%;
      width: ${rem(50)};
      height: ${rem(50)};
    }
   
    @-webkit-keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
`
