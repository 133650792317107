import React from 'react'
import { InputStyle, InputWrapperStyle, Error } from './style'

const Input = ({ error, className, register, rightInfo, isCustomized, ...props }) => {
	return (
		<InputWrapperStyle className={className}>
			<InputStyle ref={register} error={error} {...props} />
			{error && <Error isCustomized={isCustomized}>{error?.message}</Error>}
		</InputWrapperStyle>
	)
}

export default Input
