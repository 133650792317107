import React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

const LanguageProvider = ({ children }) => {
	const { messages, locale } = useSelector((state) => state.root)
	return (
		locale
		?
		<IntlProvider messages={messages[locale]} locale={locale}>
			{children}
		</IntlProvider>
			:
		<IntlProvider messages={messages['en']} locale={'en'}>
			{children}
		</IntlProvider>
	)
}

export default LanguageProvider
