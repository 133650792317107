import Popup from "reactjs-popup";
import styled from "styled-components";
import { rem } from "../../utils/common";

export const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
  }

  // use your custom style for ".popup-content"
  &-content {
    padding: ${rem(0)};
    border-radius: ${rem(4)};
    width: ${(p) => (p.W ? p.W : "50%")};
    max-width: ${(p) => rem(p.maxW || 600)};
    min-width: ${rem(320)};
    max-height: 90vh;
    overflow: auto;
  }
`;
export const PopupContent = styled.div`
  text-align: center;
`;
