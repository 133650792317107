import Axios from 'axios'
import environment from './../environment'
import events from './events'
import { AUTH_TOKEN } from './constants'
import { parseJSON } from './common'

const defaultMutationHeaders = {
	'Content-Type': 'application/json',
}

function getBaseUrl (config) {
	let {v='v1'} = config;
	return environment.apiUrl.replace('$v', v)
}

function createAxiosAdapter() {
	const axios = Axios.create();

	axios.interceptors.request.use( async config => {
		const { headers } = config;
		const token = parseJSON(sessionStorage.getItem(AUTH_TOKEN));
		config.baseURL = await getBaseUrl(config);

		if (token) {
			headers.Authorization = `Bearer ${token?.token}`
		}

		return config;
	}, (error) => { console.log(error, 'api error'); return Promise.reject(error)} );

	axios.interceptors.response.use(
		(response) => response.data,
		(err) => {
			if (!err || !err.response) return err

			const error = err.response

			let errorMessage;

			if (error.data) {
				if (error.data.ErrorResponse) {
					errorMessage = error.data.ErrorResponse.Error.Message
				} else if (error.data.error) {
					errorMessage = error.data.error
				} else {
					errorMessage = 'Unknown'
				}
			}

			if (error.status === 401) {
				events.emit('logout')
			} else {
				console.error('Error', errorMessage)
			}
			return Promise.reject(error.data)
		}
	)

	return {
		get(url, params, config) {
			return axios.get(url, { params, ...config })
		},
		post(url, body, headers = defaultMutationHeaders, config) {
			return axios.post(url, body, { headers, ...config })
		},
		put(url, body, headers = defaultMutationHeaders, config) {
			return axios.put(url, body, { headers, ...config })
		},
		patch(url, body, headers = defaultMutationHeaders, config) {
			return axios.patch(url, body, { headers, ...config })
		},
		delete(url, body, headers = defaultMutationHeaders, config) {
			return axios.delete(url, { data: body }, { headers, ...config })
		},
	}
}
export default createAxiosAdapter()
