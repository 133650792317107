import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { 
  CookiesMessageContainer, 
  CookiesMessageHolder, 
  CookiesMessageBtnClose
} from "./style";
import { Icon } from "../../components/Icon";
import ReactHtmlParser from "react-html-parser";
import {FormattedMessage, useIntl} from 'react-intl';
import { showPopup } from "../../components/Modal/slices";
import { LinkUnderline } from "../../containers/Main/components/MainForm/style";

const CookiesMessage = () => {
    const [messageVisibility, setMessageVisibility] = useState(true);  
    const intl = useIntl();
    const dispatch = useDispatch()
    const { policy } = useSelector((state) => state.main);
    const { messages, locale } = useSelector((state) => state.root);
    const onClose = () => {
      setMessageVisibility(current => !current); 
    };

    const openPolicyPopupWindow = (e) => {
      e.preventDefault();
      dispatch(showPopup({
          type: 'policy',
          data: policy,
          cookiePolicyIndex: 5
      }))
    }

    return (
      ( messageVisibility ? (
        <CookiesMessageContainer>
          <CookiesMessageHolder>
            <>
                {
                    messages && messages[locale] && Object.keys(messages[locale]).length
                    ?
                        ReactHtmlParser(intl.formatMessage({
                            id: 'login.form.cookies.message.link',
                            defaultMessage: 'To provide you with a better service, this site uses cookies! By continuing using this site, you agree to our privacy and cookie policy.',
                        }))
                    :
                        ''
                }{' '}
              {
                !!policy && (
                  messages && messages[locale] && Object.keys(messages[locale]).length
                      ?
                    <LinkUnderline onClick={openPolicyPopupWindow}>
                        <FormattedMessage
                          id='login.form.cookies.link'
                          defaultMessage='privacy and cookie policy'
                        />
                    </LinkUnderline>
                    :
                    ''
                )
              }
            </>
          </CookiesMessageHolder>
          <CookiesMessageBtnClose>
            <Icon component="closePopup" onClick={onClose} />                        
          </CookiesMessageBtnClose>
        </CookiesMessageContainer>
        ) : ''
      )
    )
}
export default CookiesMessage