import styled from 'styled-components';
import { rem } from '../../utils/common';
import { device } from '../Variable';

export const CookiesMessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: ${rem(20)};
    font-size: ${rem(14)};
    font-weight: 100;
    color: ${({ theme }) => theme.color.secondary};
    background: ${({ theme }) => theme.color.blackOne};
    opacity: 0.8;
    position: fixed;
    bottom: 0;
    z-index: 20;
    path {
        fill:  ${({ theme }) => theme.color.secondary};
    }
    @media (${device.laptopL}) {
        height: ${rem(40)};
	}
    @media (${device.tablet}) {
        height: ${rem(60)};
    }
    @media (${device.mobileL}) {
        height: ${rem(80)};
	}
    @media (${device.mobileS}) {
        height: ${rem(50)};
	}
`

export const CookiesMessageHolder = styled.div`
    text-align: center;
    span {
        color: ${({ theme }) => theme.color.secondary};
        &:hover {
            color: ${({ theme }) => theme.color.graySix};
        }
    }
    @media (${device.laptopL}) {
        width: 100vw;
        margin-bottom: 0.8rem;
        line-height: 0.7;
    }
    @media (${device.tablet}) {
        width: 95vw;
    }
    @media (${device.tabletS}) {
        width: 90vw;
    }
    @media (${device.mobileM}) {
        width: 80vw;
        line-height: 0.8;
    }
    @media (${device.mobileS}) {
        margin-bottom: 0;
        line-height: 1;
    }
`

export const CookiesMessageBtnClose = styled.div`
    position: absolute;
    right: ${rem(40)};
    cursor: pointer;
    @media (${device.laptopL}) {
        bottom: ${rem(20)}
    }
    @media (${device.laptopS}) {
        right: ${rem(10)};
    }
    @media (${device.tablet}) {
        bottom: ${rem(28)};
    }
    @media (${device.mobileL}) {
        bottom: ${rem(38)};
    }
    @media (${device.mobileS}) {
        bottom: ${rem(20)};
    }
    svg {
        width: ${rem(9)};
        height: ${rem(9)};
        @media (${device.tablet}) {
            width: ${rem(11)};
            height: ${rem(11)};
        }
    }
`