import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        isActive: false
    },
    reducers: {
        showLoader: (state) => {
            state.isActive = true;
        },
        hideLoader: (state) => {
            state.isActive = false;
        },
    },
});

export const {
    showLoader,
    hideLoader
} = loaderSlice.actions;
export const { reducer } = loaderSlice;
