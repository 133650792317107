import { createSlice } from "@reduxjs/toolkit";

export const multiMarketComboBoxSlice = createSlice({
    name: "multiMarketComboBox",
    initialState: {
        isVisible: true,
    },
    reducers: {
        showMarketControl: (state, { payload }) => {
            state.isVisible = true;
        },
        hideMarketControl: (state) => {
            state.isVisible = false;
        },
    },
});

export const { showMarketControl, hideMarketControl } = multiMarketComboBoxSlice.actions;
export const { reducer } = multiMarketComboBoxSlice;