import {
    getPropertiesStart,
    getPropertiesSuccess,
    getLanguageProviderError,
    getLanguageProviderStart,
    getLanguageProviderSuccess,
    getPropertiesError,
} from './root-slices'
import Api from '../utils/api'

export const getProperties = (shopId) => async (dispatch) => {
    try {
        dispatch(getPropertiesStart())
        Api.get(`/shop/properties/${shopId}`).then((res) => {
            dispatch(getPropertiesSuccess(res))
        })
    } catch (error) {
        dispatch(getPropertiesError())
    }
}

export const getLocale = (shopId, locale) => async (dispatch) => {
    try {
        dispatch(getLanguageProviderStart())
        Api.get(`/texts/shopId/${shopId}/language/${locale}`).then((res) => {
            dispatch(
                getLanguageProviderSuccess({
                    messages: res,
                    currentLang: locale,
                })
            )
        })
    } catch (error) {
        dispatch(getLanguageProviderError())
    }
}