import React from 'react'
import { LoaderWrapper, LoaderItem } from "./style";
import {useSelector} from "react-redux";


const Loader = () => {
    const { isActive } = useSelector((state) => state.loader)
    return (
        <LoaderWrapper isActive={isActive}>
            <LoaderItem className='loader'>
                Loading....
            </LoaderItem>
        </LoaderWrapper>
    )
}

export default Loader
