import React from 'react';
import ReactDOM from 'react-dom';
import './style/main.scss'
import 'react-perfect-scrollbar/dist/css/styles.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
